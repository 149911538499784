import React from 'react';
import {BrowserRouter as Router,  Route,  Switch} from 'react-router-dom';

import Welcome from '././components/Welcome/Welcome';
import Login from '././components/Login/Login';
import PreHome from '././components/PreHome/PreHome';
import Home from '././components/Home/Home';
import NotFound from '././components/NotFound/NotFound';


const Routes = () => (
  <Router basename="/">
      <Switch>
			<Route exact path={`${process.env.PUBLIC_URL}/`} component={Welcome}/>
			<Route path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
			<Route path={`${process.env.PUBLIC_URL}/prehome`} component={PreHome}/>
			<Route path={`${process.env.PUBLIC_URL}/home`} component={Home}/>					  	
			<Route path="*" component={NotFound}/>
      </Switch>
  </Router>
);

export default Routes;