import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import './Welcome.css';

class Welcome extends Component {
	constructor(...props) {
	super(...props); 
	this.state = {
		tipo: '',
		showFormSuccess: false
	};
    this.submitHandle = this.submitHandle.bind(this);
    this.onChange = this.onChange.bind(this);
	}  
	
  submitHandle(e) {
	  e.preventDefault();	  
    if(this.state.tipo){  
		sessionStorage.setItem('userTipo',this.state.tipo);
		window.location.href = `${process.env.PUBLIC_URL}/login`;
    }else{
		this.setState({ messages: "Debe Seleccionar una Opción de Ingreso", showFormSuccess: true }); 
	}    
   }
   onChange(e) {
   	this.setState({
   		[e.target.name]: e.target.value
   	});
   }
   renderSuccessMessage() {
		return (
			<div className="alert alert-danger" role="alert">
				{this.state.messages}
      		</div>
		);
	}
    render() {		
		if (sessionStorage.getItem('userData')) {
			 return (<Redirect to={`${process.env.PUBLIC_URL}/prevhome`}/>)
		}
		return (
			<div className="container " id="wrapper">
				<div className="row">
					<div className="col-md-8 offset-md-4">
						<h3 className="text-primary">Bienvenido</h3><p>Seleccione una opcion:</p>					
					</div>
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-8 offset-md-2">
								{this.state.showFormSuccess ? this.renderSuccessMessage() : null}
							</div>
						</div>
						<div className="row">
							<div className="col-md-8 offset-md-4">
								<form onSubmit={this.submitHandle} className="form-inline">
									<div className="form-group mx-sm-3 mb-2">										
										<select className="form-control" name="tipo" onChange={this.onChange}>				  	 
											<option value="">--Seleccione--</option>
											<option value="1">Profesor</option>
											<option value="2">Padre</option>
											<option  value="3">Estudiante</option>
										</select>
									</div>
									<button type="submit" className="btn btn-outline-primary mb-2 ml-2">Ingresar</button>
								</form>
							</div>	
						</div>	
					</div>
				</div>							
				  
			</div>
		);
    }
}

export default Welcome;