import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import './Tarea.css';
import ListaTarea from "./ListaTarea";
import AddForm from "./AddForm";
import AddFormTarea from "./AddFormTarea";
import Consulta from "./Consulta";
import Detalle from "./Detalle";
import ListaRevision from "./ListaRevision";
import Revision from "./Revision";
class Tarea extends Component {		
	render() {				
		return (
			<div className="container " id="wrapper">
				<div className="row">					
					<div className="col-md-6 offset-md-3 col-sm-6 offset-sm-4 text-center ">
						<h3 className="mt-2">AGENDA / TAREAS</h3>
					</div>
				</div>

				<Route exact path={`${process.env.PUBLIC_URL}/home/tarea`} component={ListaTarea} /* render={(props) => <ListaTarea {...props} isAuthed={true} />} */ />
				<Route path={`${process.env.PUBLIC_URL}/home/tarea/add/:id`} component={AddForm} />
				<Route path={`${process.env.PUBLIC_URL}/home/tarea/addTarea/:id`} component={AddFormTarea} />
				<Route path={`${process.env.PUBLIC_URL}/home/tarea/consulta/:id`} component={Consulta} />
				<Route path={`${process.env.PUBLIC_URL}/home/tarea/detalle/:id`} component={Detalle} />
				<Route path={`${process.env.PUBLIC_URL}/home/tarea/listRevision/:id`} component={ListaRevision} />
				<Route path={`${process.env.PUBLIC_URL}/home/tarea/revision/:id/:ida`} component={Revision} />
			</div>
		);
    }
}

export default withRouter(Tarea);