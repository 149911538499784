import React, {Component} from 'react';

import './NotFound.css';

class NotFound extends Component {
  render() {
    return (
		<div className="container" id="wrapper">
			<div className="row">
				<h2>404 Page , Contactese con su Administrador 
					  @comunicarte.com.bo
				</h2>
				<a href="https://www.comunicarte.com.bo" target="_blank" rel="noopener noreferrer">info</a>
			</div>
		</div>
    );
  }
}

export default NotFound;