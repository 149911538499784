import React, {Component} from 'react';

import './Footer.css';

class Footer extends Component {
  render() {
    return (
		<div className="container" id="footer">
			<div className="row">
				<div className="col-md-12 text-center small">
					<p>(C) Copyright <span className="text-light d-none">Giancarlo Delgadillo (c) NE2ADH</span> Propiedad 
						<a href="http://comunicarte.com.bo/"> Comunicarte	</a>, powered by Oblack.com
					</p>				
				</div>
			</div>
		</div>
    );
  }
}

export default Footer;