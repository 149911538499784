import React, {Component} from 'react';
import moment from 'moment';
import { FaCheck, FaTimes, FaComments, FaDownload, FaAddressBook } from 'react-icons/fa';
import { Badge } from 'reactstrap';
import { PostData } from '../../services/PostData';
const processString = require('react-process-string');

export default class Detalle extends Component {
	constructor(...props) {
		super(...props);
		this.state = {
			fecha: moment(),
			titulo: '',
            materia: '',
            categoria: '',
			detalle:'',
			idagenda:'',
			descripcion:'',
			recursos:[],
			data:[],
			user:[],
			idestudiante:'',
			modal: true
		}
		this.toggle = this.toggle.bind(this);			
		this.submitHandleConsulta = this.submitHandleConsulta.bind(this);			
	}
	componentWillMount() {
		if (sessionStorage.getItem("userData")) {
			let data_user = JSON.parse(sessionStorage.getItem("userData"));
			let t = sessionStorage.getItem("userTipo");
			let data_user_idestudiante = '' ;			
			if (t === '1') {
				
			} else{
				let d_data_user_idestudiante = JSON.parse(sessionStorage.getItem("userCurso"));
				data_user_idestudiante = d_data_user_idestudiante.idestudiante;
			}		
			this.setState({ user: data_user, idestudiante: data_user_idestudiante });
		} else {
			this.setState({ user: [], idestudiante: '' });
		}
	
		let postData = { id: this.props.match.params.id };
		PostData('detalle_agenda', postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				let data = responseJson.message;
				this.setState({ titulo: data.titulo,
								 detalle: data.detalle,
								 materia: data.nombre,
								 idagenda: data.idagenda,
								 recursos: data.recursos,
								 categoria: data.categoria,
								 fecha: data.fecha });
			} 
		});
		this.fetchData(this.props.match.params.id)
	}
	fetchData(idagenda) {
		let postData = { id: idagenda };
		PostData('consulta_agenda', postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				this.setState({ data: responseJson.message.data });
			} else {
				this.setState({ data: [] });
			}
		});
	}
	submitHandleConsulta(e) {
		e.preventDefault();
		let ide = 0;
		if(this.state.user.role === '1') ide = this.state.user.idestudiante;
		if(this.state.user.role === '2') ide = this.state.idestudiante;


		let form = e.target,
			postData = {
				idagenda: (form.idagenda.value) ? form.idagenda.value : '',
				idestudiante: (ide) ? ide : '',
				descripcion: (form.descripcion.value) ? form.descripcion.value : ''
			};
		PostData('save_consulta_agenda', postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				this.toggle();
				this.fetchData(this.props.match.params.id)
			}
		});
	}
	toggle(){
		this.setState({ modal : !this.state.modal});
	}

  render() {
    let config = [{
        regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim, // eslint-disable-line
        fn: (key, result) => <span key={key}>
                                 <a target="_blank" rel="noopener noreferrer" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>{`${result[1]}://`}{result[2]}.{result[3]}{result[4]}</a>{result[5]}
                             </span>
    }, {
        regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim, // eslint-disable-line
        fn: (key, result) => <span key={key}>
                                 <a target="_blank" rel="noopener noreferrer" href={`http://${result[1]}.${result[2]}${result[3]}`}>{`http://${result[1]}`}.{result[2]}{result[3]}</a>{result[4]}
                             </span>
    }];
    let stringWithLinks = this.state.categoria==='5'? this.state.detalle:"";
    let processed = processString(config)(stringWithLinks);	 
    return (
		<div className="container">
			<div className="row shadow p-3 mb-5 rounded">
				<div className="col-md-12 alert-warning pt-2">
					<h3 className='text-dark'>Detalle Agenda</h3>
				</div>
				<div className="col-md-12 alert-warning mb-4 p-4">
					<div className="form-group row">
						<label forhtml="detalle" className="col-sm-2 col-form-label text-primary">Fecha</label>
						<div className="col-sm-10">
							<span className="form-control-plaintext alert-light p-2">{`${this.state.fecha}`}</span>
						</div>
					</div>
					<div className="form-group row">
						<label forhtml="detalle" className="col-sm-2 col-form-label text-primary">Materia</label>
						<div className="col-sm-10">
							<span className="form-control-plaintext alert-light p-2">{`${this.state.materia}`}</span>
						</div>
					</div>
					<div className="form-group">
						<label forhtml="titulo" className="text-primary">Titulo</label>
						<span className="form-control-plaintext alert-light p-2">{`${this.state.titulo}`}</span>
					</div>
					<div className="form-group">
						<label forhtml="detalle" className="text-primary">Detalle</label>
                        <div>
						{
                            this.state.categoria==='5'?
                                    <p className="form-control-plaintext text-justify alert-light p-2">{processed}</p>
                                    :
                                    <p className="form-control-plaintext text-justify alert-light p-2">{`${this.state.detalle}`}</p>
                        }                        
					    </div>
					</div>
					<div className="form-group ">
						<label forhtml="detalle" className="text-primary">Recursos</label>
						<ul className="list-group small alert-light p-2">
							{
								this.state.recursos.map((item, index) => (
									<li className="list-group-item" key={index}>{item.titulo}<div className="float-right"><a href={item.url} target='_blank' rel='noopener noreferrer'>Click para Descargar   <FaDownload /></a></div></li>
								))		
							}							
						</ul>
					</div>
				</div>
				<div className="col-md-12 alert alert-info">
					<label forhtml="detalle" className="text-primary">Preguntas y Respuestas</label>
					<div className="small">
					{
						this.state.data.map((item, index) => (
							<div key={index}>
								<p className="text-justify text-body font-italic mb-0">
									{index+1}.- {item.descripcion}
								</p>
								{(item.idrespuesta)?
									<p className="text-left text-muted mb-0"><span className="text-danger">R.- </span>{item.respuesta}</p>
									:''
								}								
							</div>
						))
					}
					</div>
				</div>
				{
					(this.state.user.role === '3') ?
					''
						: <div className="col-md-12">
							{
								(this.state.modal) ?
									<Badge onClick={this.toggle} color="primary" className="float-right mb-3 mt-2" style={{cursor: 'pointer'}}>Realizar una consulta <FaComments /></Badge>
									: <form onSubmit={this.submitHandleConsulta}>
										<input type="hidden" name="idagenda" value={this.state.idagenda} />
										<div className="form-group">
											<textarea type="text" rows="3" className="form-control form-control-sm alert-info" name="descripcion" placeholder="Ingrese su consulta" required={true}></textarea>
											<small className="form-text text-muted m-0 font-weight-light">* La consulta puede sobre escribirse si ya la realizo antes.</small>
										</div>
										<button onClick={this.toggle} className="btn btn-sm btn-danger float-right mx-2 mr-2"><FaTimes /></button>
										<button type="submit" className="btn btn-sm btn-primary float-right"><FaCheck /></button>
									</form>
							}
						</div>	
				}
							
				<div className="col-md-12 mt-3">
					<button className="btn btn-success float-right" onClick={this.props.history.goBack}><FaAddressBook /> Atras</button>						
				</div>
			</div>
		</div>
    );
  }
}