import React, {Component} from 'react';
import {
	FaCloudDownloadAlt,
	FaAddressCard,
	FaAddressBook,
	FaCalendarAlt,
	FaClipboardList,
	FaBars
} from 'react-icons/fa';
import { Collapse, Button } from 'reactstrap';
import { Route, Link } from 'react-router-dom';
import Tarea from '../../components/Tarea/Tarea';
import Evento from '../../components/Evento/Evento';
import Recurso from '../../components/Recurso/Recurso';
import Boletin from '../../components/Boletin/Boletin';
import Asistencia from '../../components/Asistencia/Asistencia';
class Home extends Component {
	constructor(...props) {
		super(...props);

		this.state = {
			curso:'',
			collapse: false,
			actividad: false,
			tipo: ''
		}
		this.toggle = this.toggle.bind(this);
	}
	toggle(e) {		
		this.setState({ collapse: !this.state.collapse });
		this.setState({ actividad: e.target.id === 'evento' });
	}
  componentDidMount(){
		let t = sessionStorage.getItem("userTipo");
		this.setState({ tipo: t });
	  	if (t === '1') {
			if (sessionStorage.getItem("idmateriacursoData")){
				let postData = JSON.parse(sessionStorage.getItem("idmateriacursoData"));
				let data_cursos = JSON.parse(sessionStorage.getItem("cursosData"));			
				let array_search_curso = data_cursos.find((e) => (e.idmateriacurso === postData.idmateriacurso));
				this.setState({ curso: array_search_curso.nombre, collapse: true })				
			} else{
				console.error('User Curso Vacio');		  
			}
		} else {
			if (sessionStorage.getItem("userCurso")) {
				let postData = JSON.parse(sessionStorage.getItem("userCurso"));
				let array_search_curso = postData.hijos.find((e) => (e.idestudiante === postData.idestudiante));
				if (t === '3') 	this.setState({ curso: array_search_curso.nombre + ' / ' + postData.grado, collapse: true }) 
					else this.setState({ curso: array_search_curso.nombre +' / '+array_search_curso.grado, collapse: true })
			} else {
				console.error('User Curso Vacio');
			}
		}
  }
  render() {
	  if (!sessionStorage.getItem("userData")) {
		  window.location.href = `${process.env.PUBLIC_URL}/`;
	  }

    return (
		<div className="container" id="wrapper">			
			<div className="row">
				<div className="col-md-4">
					{
						(this.state.collapse)? 
						''
						: <Button color="primary" onClick={this.toggle} className="float-left mr-4"><FaBars /></Button>
						
					}
				</div>
				{
					(this.state.actividad)?
						''
						:(this.state.tipo==='1')?
							<div className="col-md-8">							
								<p className="p-2 font-weight-light text-md-right text-lg-right">Usted esta con: <span className="text-info">{this.state.curso}</span> </p>
							</div>
							: <div className="col-md-8">							
								<p className="p-2 font-weight-light text-md-right text-lg-right">Usted esta con el estudiante: <span className="text-info">{this.state.curso}</span> </p>
							</div>								

				}				
			</div>
			<Collapse isOpen={this.state.collapse}>
				<div className="row mt-4">
					<div className="col-lg-3 col-md-4 col-xs-6 mb-4 d-flex">
						<Link to={`${process.env.PUBLIC_URL}/home/tarea`} onClick={this.toggle} className="btn btn-primary flex-fill h-100 d-inline-block" id="tarea">
							<FaAddressBook /> Agenda
						</Link>
					</div>
					<div className="col-lg-3 col-md-4 col-xs-6 mb-4 d-flex">
						<Link to={`${process.env.PUBLIC_URL}/home/evento`} onClick={this.toggle} className="btn btn-primary flex-fill" id="evento">
							<FaCalendarAlt /> Actividades
          				</Link>
					</div>
					<div className="col-lg-3 col-md-4 col-xs-6 mb-4 d-flex">
						<Link to={`${process.env.PUBLIC_URL}/home/recurso`} onClick={this.toggle} className="btn btn-primary flex-fill">
							<FaCloudDownloadAlt /> Recursos
          				</Link>						
					</div>
					<div className="col-lg-3 col-md-4 col-xs-6 mb-4 d-flex">
						<Link to={`${process.env.PUBLIC_URL}/home/asistencia`} onClick={this.toggle} className="btn btn-primary flex-fill">
							<FaClipboardList /> Asistencia
          				</Link>
					</div>
					{
						(this.state.tipo==='2')?
						<div className="col-lg-3 col-md-4 col-xs-6 mb-4 d-flex">
							<Link to={`${process.env.PUBLIC_URL}/home/boletin`} onClick={this.toggle} className="btn btn-primary flex-fill">
								<FaAddressCard /> Boletin
							</Link>
						</div>
						:''
					}
					
				</div>
			</Collapse>			
			<div className="row">
				<Route path={`${process.env.PUBLIC_URL}/home/tarea`} component={Tarea} />
				<Route path={`${process.env.PUBLIC_URL}/home/evento`} component={Evento} />
				<Route path={`${process.env.PUBLIC_URL}/home/recurso`} component={Recurso} />
				<Route path={`${process.env.PUBLIC_URL}/home/asistencia`} component={Asistencia} />
				<Route path={`${process.env.PUBLIC_URL}/home/boletin`} component={Boletin} />
			</div>			
		</div>
    );
  }
}

export default Home;