import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { PostData } from '../../services/PostData';
import './PreHome.css';
class PreHome extends Component {
	constructor(...props) {
		super(...props);

		this.state = {
			data_cursos:[],
			data_materias:[],
			data_hijos:[],
			idestudiante:'',
			tipo:'',
			showFormSuccess: false,
			redirectToReferrer: false
		}
		this.onSubmitHandle = this.onSubmitHandle.bind(this)
		this.onChange = this.onChange.bind(this);
	}

	onSubmitHandle(e){
		e.preventDefault();
		if(this.state.tipo==='1'){
			let form = e.target,
				postData = {
					idmateriacurso: (form.idmateriacurso.value) ? form.idmateriacurso.value : ''					
				};				
			sessionStorage.setItem('idmateriacursoData', JSON.stringify(postData));
		} else {
			let form = e.target,
				postData = {
					idcurso: (form.idcurso.value) ? form.idcurso.value : '',
					idestudiante: (this.state.idestudiante) ? this.state.idestudiante : '',
					hijos: (this.state.data_hijos) ? this.state.data_hijos : ''
				};
				
			sessionStorage.setItem('userCurso', JSON.stringify(postData));
		}

		this.setState({ redirectToReferrer: true });

	}
	onChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
		const selectedIndex = e.target.options.selectedIndex;
		let ide = e.target.options[selectedIndex].getAttribute('data');
		this.setState({
			idestudiante: ide
		})		
	}
	renderSuccessMessage() {
		return (
			<div className="alert alert-danger" role="alert">
				{this.state.messages}
			</div>
		);
	}
	componentDidMount() {
		let t = sessionStorage.getItem("userTipo");
		this.setState({ tipo: t });
		if(t==='1'){			
			if (this.state.data_cursos.length===0){
				let data_cursos = JSON.parse(sessionStorage.getItem("cursosData"));
				this.setState({ data_cursos });
			} else {
				console.error('User Data Vacio');			
			}
		} else {			
			let data_user = JSON.parse(sessionStorage.getItem("userData"));
			let postData_hijo = { id: data_user.id };
			PostData('list_hijos', postData_hijo).then((result) => {
				let responseJson = result;
				if (responseJson.success) {
					 this.setState({ data_hijos: responseJson.message }); 
				}else { 
					this.setState({ data_hijos: [] }); 
				}
			});
		}

  	}
  
  render() {
	
	if (!sessionStorage.getItem("userData")) {
		window.location.href = `${process.env.PUBLIC_URL}/`;
	}
	
	if(this.state.redirectToReferrer){
		return <Redirect to={`${process.env.PUBLIC_URL}/home`} />
	}

    return (
		<div className="container" id="wrapper">
			<div className="row">
				<div className="col-md-8 offset-md-2">
					{this.state.showFormSuccess ? this.renderSuccessMessage() : null}
				</div>
			</div>
			<form onSubmit={this.onSubmitHandle}>
				<div className="row">
					<div className="col-md-8 offset-md-3">
						{
							(this.state.tipo === '1')?
								<div>
									<h4 className="text-primary">Curso-Paralelo-Materia</h4><p>Escoga su: </p>
								</div>
								: <div>
										<h4 className="text-primary">Curso-Estudiante</h4><p>Escoga hijo(a): </p>
									</div>
						}
					</div>
					<div className="clearfix"></div>
				</div>
				{
					(this.state.tipo === '1')?
					<div className="row">
						<div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 col-xs-6 mb-2">
							<div className="form-inline">
								<div className="form-group">
									<label htmlFor="idmateriacurso" className="form-label mr-sm-2 font-weight-light">Curso</label>
									<select className="form-control" name="idmateriacurso" onChange={this.onChange} required={true}>
										<option value="">--Seleccione--</option>
										{
											(this.state.data_cursos) ?
												this.state.data_cursos.map((item, index) => (
														<option value={item.idmateriacurso} key={index} >{item.nombre}</option>
												))
												: <option value="">No existe los Cursos</option>
										}
									</select>
								</div>
								<button type="submit" className="btn btn-outline-primary ml-sm-2">Aceptar</button>
							</div>
						</div>
					</div>
					:<div className="row">
						<div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 col-xs-6 mb-2">
							<div className="form-inline">
								<div className="form-group">
									<label htmlFor="idcurso" className="form-label font-weight-light mr-2">Estudiante:</label>
									<select className="form-control" name="idcurso" onChange={this.onChange} required={true}>
										<option value="">--Seleccione--</option>
										{
											(this.state.data_hijos) ?
												this.state.data_hijos.map((item, index) => (
														<option value={item.idcurso} key={index} data={item.idestudiante}>{item.nombre}</option>
												))
												: <option value="">No existe los Estudiante</option>
										}

									</select>
								</div>
								<button type="submit" className="btn btn-outline-primary ml-sm-2">Aceptar</button>
							</div>
						</div>
					</div>
				}
							
			</form>
		</div>
    );
  }
}

export default PreHome;