import React, { Component } from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import {PostData} from '../../services/PostData';

class AddForm extends Component {
	constructor(...props) {
		super(...props);

		this.state = {	
			titulo:'',
			detalle:'',
            tipo:'1',
			idmateria:'',
			idagenda:'',
			fecha:null,
			optionsRecursos: [],
			optionsEstudiantes: [],
			value: [],			
			value_tipo: [],			
			redirectToReferrer: false			
		}				
		this.submitHandle = this.submitHandle.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeFecha = this.handleChangeFecha.bind(this);
		this.handleChangeTipo = this.handleChangeTipo.bind(this);
		this.handleChangeClase = this.handleChangeClase.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangePadres = this.onChangePadres.bind(this);
	}
    
    onChange(value) {		
		this.setState({ value: [].concat(value) });
    }
    
	handleChangeFecha(date) {		
        if(date)
            this.setState({ fecha: date });
	}

	onChangePadres(value) {		
		this.setState({ value_tipo: [].concat(value) });
	}
	
	handleChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	}

	handleChangeTipo(event) {
		this.setState({tipo: event.target.value});
    }
    
	handleChangeClase(event) {
		this.setState({clase: event.target.value});
	}

	submitHandle = (e) => {
		e.preventDefault();		
		let method = 'save_agenda';
		let idrecurso = this.state.value;
		let idestudiante = this.state.value_tipo;
		let recursos = idrecurso.map(k => k.value);
		let estudiantes = idestudiante.map(k => k.value);
		let form = e.target,
			postData = {
				titulo: (form.titulo.value) ? form.titulo.value : '',
				detalle: (form.detalle.value) ? form.detalle.value : '',
                categoria: (this.state.tipo) ? this.state.tipo : '',
				idrecurso: (this.state.value) ? recursos : '',
				idestudiante: (this.state.value_tipo) ? estudiantes : '',
				fecha: (this.state.fecha) ? this.state.fecha : ''
			};
			let data_cursos = JSON.parse(sessionStorage.getItem("cursosData"));
			let cursoData = JSON.parse(sessionStorage.getItem("idmateriacursoData"));
			let array_search_curso = data_cursos.find((e) => (e.idmateriacurso === cursoData.idmateriacurso));			
			if (this.props.match.params.id !== 'new') {
				method = 'update_agenda';
				postData['idagenda'] = (this.state.idagenda) ? this.state.idagenda : 0;
			} else{
				postData['idcurso'] = (array_search_curso.idcurso) ? array_search_curso.idcurso : 0;
			}
			postData['idmateria'] = (array_search_curso.idmateria) ? array_search_curso.idmateria : 0;
			PostData(method, postData).then((result) => {
				let responseJson = result;				
				if(responseJson.success){         
					setTimeout(this.setState({
						redirectToReferrer: true
					}), 3000)
				} 
			});			
	}
	isArray(object) {
		if (object.constructor === Array) return true;
		else return false;
    }
    
    componentWillMount(){
        let data_cursos = JSON.parse(sessionStorage.getItem("cursosData"));
		let cursoData = JSON.parse(sessionStorage.getItem("idmateriacursoData"));
		let array_search_curso = data_cursos.find((e) => (e.idmateriacurso === cursoData.idmateriacurso));
		let postData = { id: array_search_curso.idmateria };
		PostData('recursos_agenda', postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				let data = responseJson.message;
				const optionsRecursos = data.map(recurso => Object.assign({}, { label: recurso.titulo, value: recurso.idrecurso }))
				this.setState({ optionsRecursos: optionsRecursos });
			} else {
				this.setState({ optionsRecursos: [] });
			}
		});
		let postData2 = { id: array_search_curso.idcurso };
		PostData('estudiantes_agenda', postData2).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				let data = responseJson.message;
				const optionsEstudiantes = data.map(estudiante => Object.assign({}, { label: estudiante.nombre, value: estudiante.idestudiante }))
				this.setState({ optionsEstudiantes: optionsEstudiantes });
			} else {
				this.setState({ optionsEstudiantes: [] });
			}
		});	
    }

	componentDidMount(){
		if (this.props.match.params.id !== 'new'){			
			let postData = { id: this.props.match.params.id };
			PostData('edit_agenda', postData).then((result) => {
                let responseJson = result;				
				if(responseJson.success){
					let data = responseJson.message; 					        					
					this.setState({titulo: data.titulo});
					this.setState({detalle: data.detalle});
					this.setState({tipo: data.categoria});		
					this.setState({idagenda: data.idagenda});					
					this.setState({idmateria: data.idmateria});					
					this.setState({fecha: data.fecha});										
					if(data.idrecurso){
						var arrId = data.idrecurso.split(",").map((item) => item.trim());
						if (this.isArray(arrId)) result = arrId;  else 	result = [data.idrecurso];
						let approved = this.state.optionsRecursos.filter(v => result.indexOf(v.value) !== -1 );
						this.setState({value: approved});
					}
					if(data.idestudiante){
						var arrIde = data.idestudiante.split(",").map((item) => item.trim());
						if (this.isArray(arrIde)) result = arrIde;  else 	result = [data.idestudiante];
						let approved = this.state.optionsEstudiantes.filter(v => result.indexOf(v.value) !== -1 );
						this.setState({value_tipo: approved});
					}
					
				} else {
					this.setState({ redirectToReferrer: true});
				}
			});
        }
        
        let startDate = sessionStorage.getItem('startDate');
        var dateObj = new Date(startDate);
        var momentObj = moment(dateObj);
        var momentString = momentObj.format('YYYY-MM-DD'); 
        this.setState({ fecha: momentString});
		
	}
	render() {		
		if (this.state.redirectToReferrer) {
			return (<Redirect to={`${process.env.PUBLIC_URL}/home/tarea`}/>)
		}
		return (
			<div className="container shadow p-3 mb-5 rounded">
				<div className="row">					
					<div className="col-md-12">
						<h3>{(this.props.match.params.id !== 'new')?'Editar':'Crear'} Agenda</h3>				
					</div>					
					<div className="col-md-12">
						<form  onSubmit={this.submitHandle}>
							<div className="form-group row">
								<label forhtml="detalle" className="col-sm-2 col-form-label">Fecha</label>
								<div className="col-sm-10">
                                    <div className="input-group mb-3">
                                        <DatePicker																					
                                            selected={moment(this.state.fecha)}
                                            onChange={this.handleChangeFecha}
                                            locale={moment.locale('es-do')}	
                                            className="form-control"
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="basic-addon2">
                                                <FaCalendarAlt />
                                            </span>
                                        </div>
                                    </div>
								</div>
							</div>
							<div className="form-group">
								<label forhtml="titulo">Titulo</label>								
								<input type="text" className="form-control" name="titulo" required={true} placeholder="Ingrese Titulo" value={this.state.titulo || ''} onChange={this.handleChange} />
							</div>							
                            <div className="form-group">
							    <label forhtml="tipo">Tipo de Agenda</label>
								<select className="form-control" name="tipo" value={this.state.tipo} onChange={this.handleChangeTipo}>
									<option value="1">General / Tarea</option>
									<option value="2">Comunicado</option>
									<option value="3">Llamada de Atención</option>
                                    <option value="4">Actividad en Clase</option>
									<option value="5">Horario de Clases Virtuales</option>
								</select>
								{
									(this.state.tipo==='3')?
									<div className="col-sm-12">									
										<label forhtml="tipo">Dirigido a:</label>
										<Select
											name="dirigido"											
											isMulti
											value={this.state.value_tipo || ''}
											onChange={this.onChangePadres}
											options={this.state.optionsEstudiantes}
										/>
									</div>
									:''
								}
							</div>
							<div className="form-group">
								<label forhtml="detalle">Detalle</label>
								<textarea className="form-control" name="detalle" rows="8" placeholder="Ingrese Detalle" value={this.state.detalle || ''} onChange={this.handleChange}></textarea>
							</div>
							<div className="form-group">
								<label forhtml="recurso">Recursos</label>
								<Select
									name="idrecurso"
									isMulti
									value={this.state.value || ''}
									onChange={this.onChange}
									options={this.state.optionsRecursos}
								/>
							</div>                            							
							<button type="submit" className="btn btn-outline-success float-right ml-2">Guardar</button>
						</form>
							<button className="btn btn-outline-danger float-right" onClick={this.props.history.goBack}>Cancelar</button>
					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(AddForm);