import React, { Component } from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {PostData} from '../../services/PostData'; 
import GooglePicker from 'react-google-picker';
import { FaPaperclip, FaTrash } from 'react-icons/fa';
import { Badge } from 'reactstrap';


class AddFormTarea extends Component {
	constructor(...props) {
		super(...props);

		this.state = {
            idtarea:'',	
            titulo:'',
            comentario: '',
            user: '',
            adjuntos: [],	
		}				
        this.submitHandle = this.submitHandle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.delete = this.delete.bind(this);
    }

    handleChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	}

	submitHandle = (e) => {
        e.preventDefault();
        let method = 'save_tarea';
        let dataTarea = this.state.adjuntos;
        let form = e.target,
			postData = {
                comentario: (form.comentario.value) ? form.comentario.value : '',
                idestudiante: (this.state.user) ? this.state.user.idestudiante : '',                		
				adjuntos: (this.state.adjuntos) ? dataTarea : '',
            };        
        postData['idagenda'] = (this.props.match.params.id) ? this.props.match.params.id : 0;
        if (this.state.idtarea) {
            method = 'update_tarea';
            postData['idtarea'] = (this.state.idtarea) ? this.state.idtarea : 0;
        }
		PostData(method, postData).then((result) => {
            let responseJson = result;				
            if(responseJson.success){         
                setTimeout(this.setState({
                    redirectToReferrer: true
                }), 3000)
            } 
        });		
    }

    componentWillMount(){
        if (sessionStorage.getItem("userData")) {
            let data_user = JSON.parse(sessionStorage.getItem("userData"));
			this.setState({ user: data_user });
		}	
    }

    componentDidMount(){        
        let postData2 = { idagenda: this.props.match.params.id, idestudiante: this.state.user.idestudiante };
		PostData('detalle_tarea', postData2).then((result) => {
            let responseJson = result;
			if (responseJson.success) {
                let data = responseJson.message;
                this.setState({comentario: data.comentario});
                this.setState({idtarea: data.idtarea});
                let dataJSON = JSON.parse(data.adjuntos);
                this.setState({adjuntos: dataJSON});	
			} 
        });        
        let postData = { id: this.props.match.params.id };
		PostData('detalle_agenda', postData).then((result) => {
            let responseJson = result;
			if (responseJson.success) {
				let data = responseJson.message;
				this.setState({ titulo: data.titulo });
			} 
        });
    }

    delete(id){
        this.setState(prevState => ({
            adjuntos: prevState.adjuntos.filter(el => el.name !== id )
        }));
     }
     
	render() {	
		if (this.state.redirectToReferrer) {
			return (<Redirect to={`${process.env.PUBLIC_URL}/home/tarea`}/>)
		}
		return (
			<div className="container shadow p-3 mb-5 rounded">
				<div className="row">					
					<div className="col-md-12">
						<h3>Adjuntar - Tarea</h3>				
					</div>					
					<div className="col-md-12">
						<form  onSubmit={this.submitHandle}>							
							<div className="form-group">
								<label forhtml="titulo">Titulo</label>								
								<span className="form-control-plaintext text-secondary">{`${this.state.titulo}`}</span>
							</div>							
							<div className="form-group">
								<label forhtml="detalle">Comentario</label>
								<textarea className="form-control" name="comentario" rows="2" placeholder="Ingrese Comentario" required={true} value={this.state.comentario || ''} onChange={this.handleChange}></textarea>
							</div>
                            <div className="form-group">
								<label forhtml="detalle">Subir Archivos</label>
                                <div style={{width: '10%'}}>                                    
                                    <GooglePicker                                     
                                        clientId={`${process.env.REACT_APP_CLIENT_ID}`}
                                        //developerKey={'AIzaSyCH8E_GFTlEpkfHE6jy9F8F-YXGG5afuuQ'}
                                        scope={['https://www.googleapis.com/auth/drive.file']}
                                        onChange={data => console.log('on change:', data)}                                        
                                        onAuthFailed={data => console.log('on auth failed:', data)}
                                        multiselect={true}
                                        navHidden={true}
                                        authImmediate={false}
                                        viewId={'DOCS'}                                                
                                        mimeTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                        createPicker={ (google, oauthToken) => {
                                            const googleViewId = google.picker.ViewId.DOCS;
                                            const uploadView = new google.picker.DocsUploadView();
                                            const docsView = new google.picker.DocsView(googleViewId)
                                                .setIncludeFolders(true)
                                                .setSelectFolderEnabled(true);

                                            const picker = new window.google.picker.PickerBuilder()
                                                .enableFeature(google.picker.Feature.SIMPLE)
                                                .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                                                .addView(docsView)
                                                .addView(uploadView)/*DocsUploadView added*/
                                                .setOAuthToken(oauthToken)
                                                .setCallback((data)=>{
                                                if (data.action === google.picker.Action.PICKED) {
                                                    var arrayDocs= data.docs;
                                                    console.log(arrayDocs);
                                                    if(arrayDocs.length > 0){
                                                        let temp = this.state.adjuntos || [];
                                                        arrayDocs.forEach(element => {
                                                            var embedUrl = element.url;
                                                            var name = element.name;                                                                   
                                                            temp.push({name: name, url: embedUrl });
                                                        });
                                                        this.setState({ adjuntos: temp });
                                                    } else {
                                                        var embedUrl = data.docs[0].embedUrl;
                                                        var name = data.docs[0].name;   
                                                        this.setState({ adjuntos: [].concat({name: name, url: embedUrl }) });
                                                    }
                                                }
                                                });
                                            picker.build().setVisible(true);
                                        }}>
                                            <span><Badge color="primary">Adjuntar<FaPaperclip /></Badge></span>
                                            <div className="google"></div>
                                    </GooglePicker>                                
                                </div>
							</div>
                            <div className="form-group">
                                <Child delete={this.delete} data={this.state.adjuntos}/> 
                            </div>
							<button type="submit" className="btn btn-outline-success float-right ml-2" >Enviar</button>
						</form>
                        <button className="btn btn-outline-danger float-right" onClick={this.props.history.goBack}>Cancelar</button>
					</div>
				</div>
			</div>
		);
	}
}

class Child extends React.Component{

    delete(id){
        this.props.delete(id);
    }
    
    render(){
       return(
          <ul>
            {
               this.props.data && this.props.data.map((el, index)=>
                   <li key={index}>{el.name} <Badge onClick={this.delete.bind(this, el.name)} color='danger'><FaTrash /></Badge></li>
               )
            }
          </ul>
       )
    }
 }



export default withRouter(AddFormTarea);