import React, { Component } from 'react';
import { FaCalendarAlt, FaCheck, FaCheckCircle,FaTimesCircle } from 'react-icons/fa';
import DatePicker from 'react-datepicker';

import { PostData } from '../../services/PostData';
import moment from 'moment';
import 'moment/locale/es-do';
import getDay from "date-fns/get_day";

class ListaAsistencia extends Component {
	constructor(...props) {
		super(...props);

		this.state = {			
			startDate: moment(),
			user:'',
			fecha:'',
			idcurso:'',
			mode: false,
			idmateria:'',			
			idasistencia:'',
			idestudiante:'',
			showFormSuccess: false,
			data: []
		}
		this.handleChange = this.handleChange.bind(this);
		this.submitHandle = this.submitHandle.bind(this);				
	}

	componentDidMount() {
		if (sessionStorage.getItem("userData")) {
			let data_user = JSON.parse(sessionStorage.getItem("userData"));
			this.setState({ user: data_user });
		}		
				
		this.fetchData(this.state.startDate);
	}

	isWeekday = date => {
		const day = getDay(date);
		return day !== 0 && day !== 6;
	  };

	fetchData(date) {		
		if (date) {
			let data_user = JSON.parse(sessionStorage.getItem("userData"));
						
			if (data_user.role==='3'){
				let cursoData = JSON.parse(sessionStorage.getItem("idmateriacursoData"));
				let data_cursos = JSON.parse(sessionStorage.getItem("cursosData"));
				let array_search_curso = data_cursos.find((e) => (e.idmateriacurso === cursoData.idmateriacurso));									
				let postData = { fecha: date.format('YYYY-MM-DD'), id: array_search_curso.idcurso, idm: array_search_curso.idmateria};
				this.setState({ fecha: date.format('YYYY-MM-DD'), idcurso: array_search_curso.idcurso, idmateria: array_search_curso.idmateria})
				PostData('list_asistencias', postData).then((result) => {
					let responseJson = result;
					if (responseJson.success) {
						this.setState({ data: responseJson.message.data, mode: responseJson.message.mode, idasistencia: responseJson.message.idasistencia });
					} else {
						this.setState({ data: [], mode: responseJson.message.mode, idasistencia: responseJson.message.idasistencia });
					}
				});
			} else{
				if (data_user.role === '2') {
					let cursoData = JSON.parse(sessionStorage.getItem("userCurso"));
					let postData = { fecha: date.format('YYYY-MM-DD'), id: cursoData.idcurso, idestudiante: cursoData.idestudiante };
					PostData('list_asistencias_estudiantes', postData).then((result) => {
						let responseJson = result;
						if (responseJson.success) {						
							this.setState({ data: responseJson.message });
						} else {
							this.setState({ data: [] });
						}
					});
				} else{
					if (data_user.role === '1') {
						let cursoData = JSON.parse(sessionStorage.getItem("userData"));
						let postData = { fecha: date.format('YYYY-MM-DD'), id: cursoData.idcurso, idestudiante: cursoData.idestudiante };
						PostData('list_asistencias_estudiantes', postData).then((result) => {
							let responseJson = result;
							if (responseJson.success) {
								this.setState({ data: responseJson.message });
							} else {
								this.setState({ data: [] });
							}
						});
					}
				}				
			}
		}
	}

	onToggle(index, e) {
		let newItems = this.state.data.slice();
		newItems[index].checked = !newItems[index].checked
		this.setState({
			data: newItems
		})
		this.setState({idestudiante: JSON.stringify(this.state.data.filter(item => item.checked)) })
	}

	handleChange(date) {
		if (date) {			
			this.setState({ startDate: date });
			this.fetchData(date);			
		}
	}
	
	submitHandle = (e) => {
		e.preventDefault();
		let method = 'save_asistencia';
		let postData = {
				idcurso: (this.state.idcurso) ? this.state.idcurso : 0,
				idmateria: (this.state.idmateria) ? this.state.idmateria : 0,
				idestudiante: (this.state.idestudiante) ? this.state.idestudiante : [],
				fecha: (this.state.fecha) ? this.state.fecha : 0
			};		
		PostData(method, postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				this.setState({ showFormSuccess: true, mode: true })
			}
		});
	}

	renderWelcomeMessage() {
				
		return (
			<div className="alert alert-success p-1" role="alert">
				<p className="alert-heading p-0 m-0">Se guardo Correctamente</p>
			</div>
		);
	}

	render() {
		if(this.state.showFormSuccess===true){
			setTimeout(function () {
				this.setState({showFormSuccess: false});
			}.bind(this), 2000)
		}
		return (
			<div className="shadow p-3 mb-5 rounded">
				<div className="row">
					<div className="col-md-4">
						<div className="input-group mb-3">
						{
							(this.state.user.role==='3')?
							<DatePicker																					
								dateFormat="DD/MM/YYYY"
								selected={this.state.startDate}
								onChange={this.handleChange}
								minDate={moment()}
								maxDate={moment().add(0, "days")}
								disabledKeyboardNavigation								
								locale={moment.locale('es-do')}							
								className="form-control"
							/>
							:<DatePicker																					
								dateFormat="DD/MM/YYYY"
								selected={this.state.startDate}
								onChange={this.handleChange}
								filterDate={this.isWeekday}									
								locale={moment.locale('es-do')}							
								className="form-control"
							/>
						}
							<div className="input-group-append">
								<span className="input-group-text" id="basic-addon2">
									<FaCalendarAlt />
								</span>
							</div>
						</div>
					</div>
					<div className="col-md-8">
						{this.state.showFormSuccess !== false ? this.renderWelcomeMessage() : null}						
					</div>					
				</div>
				{
					(this.state.user.role==='3')?
					<div className="row">
						<div className="col-md-12">
							<div className="table-responsive">							
								<table className="table table-sm table-hover tbody2">
									<thead className="thead-dark">
										<tr>
											<th scope="col">#</th>
											<th scope="col">Nombres</th>
											<th scope="col" className="text-center"><span> Asistio / No Asistio</span></th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.data.map((item, index) => (
												<tr key={index}>
													<th scope="row">
														{index+1}
													</th>
													<td>
														<p className="text-left m-0 p-0 text-primary">{item.nombre}</p>
													</td>
													<td className="text-center">
														{ (this.state.mode===true)?
																(item.checked === true) ?
																	<span className="text-success"><FaCheckCircle /></span>
																	: <span className="text-danger"><FaTimesCircle /></span>
															:<input type="checkbox" checked={item.checked} onChange={this.onToggle.bind(this, index)} />
														}
																													
													</td>
												</tr>
											))
										}
									</tbody>
								</table>							
							</div>
						</div>					
						<div className="col-md-12">													
							{
								(this.state.mode === true) ?
								''	
								: <button type="button" onClick={this.submitHandle} className="btn btn-success float-right"> Guardar Asistencia Nueva <FaCheck /> </button>									
							}
						</div>
					</div>
					: <div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
								<table className="table table-sm table-hover tbody2">
									<thead className="thead-dark">
										<tr>
											<th scope="col">#</th>
											<th scope="col">Nombres</th>
											<th scope="col" className="text-center">Asistio / No Asistio</th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.data.map((item, index) => (
												<tr key={index}>
													<th scope="row">
														{index + 1}
													</th>
													<td>
														<p className="text-left m-0 p-0 text-primary">{item.nombre}</p>
													</td>
													<td className="text-center">
														{
															(item.checked === true) ?
																<span className="text-success"><FaCheckCircle /></span>
																: <span className="text-danger"><FaTimesCircle /></span>
														}
													</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>				
				}
			</div>
		);
	}

}

export default ListaAsistencia;