import React, {Component} from 'react';
import moment from 'moment';
import { FaDownload, FaAddressBook } from 'react-icons/fa';

import { PostData } from '../../services/PostData';

export default class Revision extends Component {
	constructor(...props) {
		super(...props);
		this.state = {
			fecha: moment(),
			titulo: '',
            materia: '',
            nombre: '',
            nota: 0,
            idagenda:'',
            mensaje: '',
			comentario:'',
			adjuntos:[]
        }
        
        this.submitHandle = this.submitHandle.bind(this);				
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
        });
        this.setState({mensaje:''});
    }
    
    submitHandle = (e) => {
        e.preventDefault();	
        let form = e.target,
			postData = {
                idtarea: this.props.match.params.id,
				nota: (form.nota.value) ? form.nota.value : '',
            };
        let method = 'update_tarea2';
        PostData(method, postData).then((result) => {
            let responseJson = result;				
            if(responseJson.success){         
                this.setState({mensaje:'Guardado con exito !!'});               
            } 
        });	
    }
    
    componentDidMount() {
        let postData = { id: this.props.match.params.id };        
		PostData('detalle_tarea2', postData).then((result) => {
            let responseJson = result;
			if (responseJson.success) {
                let data = responseJson.message;
                this.setState({ idagenda: data.idagenda,
                                comentario: data.comentario,
                                nombre: data.nombre,
                                nota: data.nota                                
                            });
                let dataJSON = JSON.parse(data.adjuntos);
                this.setState({adjuntos: dataJSON});	
			} 
        });   
        let postData2 = { id: this.props.match.params.ida };
		PostData('detalle_agenda', postData2).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				let data = responseJson.message;
                this.setState({  titulo: data.titulo,
                                 materia: data.nombre,								
								 idagenda: data.idagenda,
								 fecha: data.fecha });
			} 
		}); 
    }	

    render() {
	 
        return (
            <div className="container">
                <div className="row shadow p-3 mb-5 rounded">
                    <div className="col-md-12">
                        <h3>Asignar Nota - Tarea</h3>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group row">
                            <label forhtml="fecha" className="col-sm-2 col-form-label text-primary">Fecha</label>
                            <div className="col-sm-10">
                                <span className="form-control-plaintext text-secondary">{`${this.state.fecha}`}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label forhtml="materia" className="col-sm-2 col-form-label text-primary">Materia</label>
                            <div className="col-sm-10">
                                <span className="form-control-plaintext text-secondary">{`${this.state.materia}`}</span>
                            </div>
                        </div>
                        <div className="form-group row">                            
                            <label forhtml="estudiante" className="col-sm-2 col-form-label text-primary">Estudiante</label>
                            <div className="col-sm-10">
                                <span className="form-control-plaintext text-secondary">{`${this.state.nombre}`}</span>
                            </div>
                        </div>                        
                        <div className="form-group row">
                            <label forhtml="archivos" className="col-sm-2 col-form-label text-primary">Archivos</label>
                            <div className="col-sm-10">
                                <ul className="list-group small">
                                    {
                                        this.state.adjuntos && this.state.adjuntos.map((item, index) => (
                                            <li className="list-group-item" key={index}>- {item.name}<div className="float-right"><a href={item.url} download target='blank'>Click para Visualizar   <FaDownload /></a></div></li>
                                        ))		
                                    }							
                                </ul>
                            </div>
                        </div>
                        <div className="form-group row">                            
                            <label forhtml="estudiante" className="col-sm-2 col-form-label text-primary">Nota</label>
                            <div className="col-sm-10">
                                <form  onSubmit={this.submitHandle} className="row">
                                    <input type="number" className="form-control" name="nota" required={true} value={this.state.nota || ''} onChange={this.handleChange} style={{width: '10%'}} />
                                    <button type="submit" className="btn btn-outline-success ml-2">Guardar Nota</button>
                                    <span className="form-control-plaintext">{`${this.state.mensaje}`}</span> 
                                </form>
                            </div>
                        </div> 
                    </div>     
                    <div className="col-md-12 mt-3">
                        <button className="btn btn-success float-right" onClick={this.props.history.goBack}><FaAddressBook /> Atras</button>						
                    </div>
                </div>
            </div>
        );
    }
}