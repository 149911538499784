import React, { Component } from 'react';
import { PostData } from '../../services/PostData';
class ListaBoletin extends Component {
	constructor(...props) {
		super(...props);

		this.state = {
			rudeoci: '',
			fechanacimiento:''
		}	
	}

	componentDidMount() {
		if (sessionStorage.getItem("userCurso")) {
			let data = JSON.parse(sessionStorage.getItem("userCurso"));
			let postData = { id: data.idestudiante};
			PostData('list_estudiante_boletin', postData).then((result) => {
				let responseJson = result;
				if (responseJson.success) {
					this.setState({ rudeoci: responseJson.message.rudeoci, fechanacimiento: responseJson.message.fechanacimiento });
				} else {
					this.setState({ rudeoci: '',fechanacimiento: '' });
				}
			});
		}
	}
	
	render() {
	
		return (
			<div className="shadow p-3 mb-5 rounded">
				<div className="row">
					<div className="col-md-12">
						<p>DATOS DEL ESTUDIANTE</p>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<form action="http://siged.sie.gob.bo/acceso/consultaLibreta/buscar" method="POST"  className="form-inline">
						<div className="form-group mb-2">
							<label forhtml="rudeoci">Rude o CI: </label>
							<input type="email" className="form-control ml-1" name="form[rudeoci]"  value={this.state.rudeoci} readOnly={true} />
						</div>
						<div className="form-group mx-sm-3 mb-2">
							<label forhtml="fechanacimiento">Fecha de Nacimiento: </label>
							<input type="email" className="form-control ml-1" name="form[fechaNacimiento]" value={this.state.fechanacimiento} readOnly={true} />
						</div>							
							<button type="submit" className="btn btn-primary mb-2">Visualizar Boletin</button>
						</form>
					</div>
					<div className="col-md-12 mt-5">
					<div className="alert alert-success" role="alert">
					<h4 className="alert-heading">Nota:</h4>
					<p>El siguiente formulario le llevara a otra pestaña de navegacion, por tanto usted puede regresar al sistema utilizando el boton "atras" de su navegador.</p>
					<hr />
					<p className="mb-0 small">La actual direccion donde se verifica las notas son localizadas en: <span className="text-primary">http://siged.sie.gob.bo</span> .</p>
					</div>
					</div>
				</div>

			</div>
		);
	}

}

export default ListaBoletin;