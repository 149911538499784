import React, { Component } from 'react';
import './App.css';
import Routes from './routes';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

class App extends Component {
	constructor() {
		super();
		this.state = {
			appName: "S.A.E. :: Comunicarte"
        }
        if (!sessionStorage.getItem('path_url')) {
            sessionStorage.setItem("path_url", window.location.href)
        }
	}
	componentDidMount() {
		document.title = this.state.appName
	}

	render() {
		return (
			<div className="container-fluid" id="wrapper">
				<Header name={this.state.appName}/>
				<Routes name={this.state.appName}/>
				<hr/>
				<Footer/>
			</div>
		);
	}
}

export default App;
