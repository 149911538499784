import React, { Component } from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import { Progress } from 'reactstrap';
import Select from 'react-select';
import './Recurso.css';
import {PostData} from '../../services/PostData';
class AddForm extends Component {
	constructor(...props) {
		super(...props);

		this.state = {	
			titulo:'',
			url:'',
			filename: null,
			size:0,
			idrecurso:'',
			optionsRecursos: [],
			value: [],
			image: '',
			loading:false,
			secondsElapsed: 0,
			type:'',
			idmateria:'',
			showFormError: false,			
			redirectToReferrer: false			
		}				
		this.submitHandle = this.submitHandle.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeRecurso = this.onChangeRecurso.bind(this);
		this.customFilter = this.customFilter.bind(this);
		this.incrementer = null;
	}
	
	handleChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
		
	}
	
	submitHandle = (e) => {
		e.preventDefault();
		this.incrementer = setInterval( () =>
		this.setState({
			secondsElapsed: this.state.secondsElapsed + 1024
		})
		, 500);		
		let method = 'save_recurso';
		let form = e.target, 
			postData = {
				titulo: (form.titulo.value) ? form.titulo.value : '',
				url: (form.url.value) ? form.url.value : '',
				idcodigoqr: (this.state.value) ? this.state.value.value : '',				
				file: this.state.image,
				type: this.state.type				
			};
		
			if(this.props.match.params.id !== 'new') {
				method = 'update_recurso';
				postData['idrecurso'] = (form.idrecurso.value) ? form.idrecurso.value : 0;
				postData['idcodigoqr'] = (form.idcodigoqr.value) ? form.idcodigoqr.value : 0;
			} else {
				let cursoData = JSON.parse(sessionStorage.getItem("idmateriacursoData"));
				let data_cursos = JSON.parse(sessionStorage.getItem("cursosData"));
				let array_search_curso = data_cursos.find((e) => (e.idmateriacurso === cursoData.idmateriacurso));				
				postData['idmateria'] = (array_search_curso.idmateria) ? array_search_curso.idmateria : 0;
			}
			PostData(method, postData).then((result) => {
				let responseJson = result;				
				if(responseJson.success){         					
					clearInterval(this.incrementer);
					this.setState({
						redirectToReferrer: true,
						secondsElapsed: 0
					});				
				} 
			});	
	}
	
	componentDidMount(){		
		let postData2 = { id: 0 };
		PostData('list_recursos_qr', postData2).then((result) => {				
            let responseJson = result;
			if (responseJson.success) {
				let data = responseJson.message;
				const optionsRecursos = data.map(codigoqr => Object.assign({}, { label: codigoqr.titulo, value: codigoqr.idcodigoqr, tags: codigoqr.tags, link:codigoqr.link }))				
				this.setState({ optionsRecursos: optionsRecursos });
			} else {
				this.setState({ optionsRecursos: [] });
			}
		});		
		if (this.props.match.params.id !== 'new'){			
			let postData = { id: this.props.match.params.id };
			PostData('edit_recurso', postData).then((result) => {
				let responseJson = result;				
				if(responseJson.success){
					let data = responseJson.message; 					        					
					this.setState({titulo: data.titulo});
					this.setState({url: data.url});					
					this.setState({idrecurso: data.idrecurso});
					let result = [data.idcodigoqr];
					let approved = this.state.optionsRecursos.filter(v => result.indexOf(v.value) !== -1 );
					this.setState({value: approved});						
				} else {
					this.setState({ redirectToReferrer: true});
				}
			});			
		}
		
	}

	onChange(e) {
		let files = e.target.files || e.dataTransfer.files;
		var names_arr = ['jpg','png','doc','docx','pdf','xls','xlsx'];
		if (!files.length)
			return;
		let ext = files[0].name.split('.').pop().toLowerCase();
		let sizes = files[0].size/1024/1024; 
		if((names_arr.indexOf(ext)!==-1)){
			if(sizes){
				this.createImage(files[0]);
				this.setState({	showFormError: false, filename: files[0].name, size: sizes * 1024 });
			} else{
				this.setState({	showFormError: true, filename: null, size: 0	});
			}
		}
		else{
			this.setState({	showFormError: true	});
		}
	}

	onChangeRecurso(value) {		
		this.setState({url: value.link});	
		this.setState({ value });

	}

	customFilter(option, searchText) {
		if (
			option.data.tags.toLowerCase().includes(searchText.toLowerCase())
		  ) {
			return true;
		  } else {
			return false;
		  }
		
	  }


	createImage(file) {
		let reader = new FileReader();
		let extension = file.name.split('.').pop().toLowerCase();		
		reader.onload = (e) => {
		this.setState({
			image: e.target.result,
			type: extension
		})
		};
		reader.readAsDataURL(file);
	}
	
	render() {
		const formattedSeconds = (sec) =>  sec + ' kb';
		if (this.state.redirectToReferrer) {
			return (<Redirect to={`${process.env.PUBLIC_URL}/home/recurso`}/>)
		}
		return (
			<div className="container shadow p-3 mb-5 rounded">
				<div className="row">					
					<div className="col-md-12">
						<h3>{(this.props.match.params.id !== 'new')?'Editar':'Crear'} Recurso</h3>				
					</div>
															
					<div className="col-md-12">
					{(this.state.secondsElapsed === 0 ||
					this.state.secondsElapsed > this.state.size
					? ''
					: <Progress animated bar color="info" value={this.state.secondsElapsed} max={this.state.size}>{formattedSeconds(this.state.secondsElapsed)}</Progress>
					)}
												
					
						<form ref="uploadForm" onSubmit={this.submitHandle} encType="multipart/form-data">						    						
							<input type="hidden" name="idrecurso" value={this.state.idrecurso}/>														
							<div className="form-group">
								<label forhtml="titulo">Titulo</label>								
								<input type="text" className="form-control" name="titulo"  placeholder="Ingrese Titulo" value={this.state.titulo} onChange={this.handleChange} required={true} />
							</div>
							<div className="form-group">
								<label forhtml="url">URL</label>
								<input type="text" className="form-control" name="url" placeholder="https://url-enlace-del-recurso.com"  value={this.state.url} onChange={this.handleChange} />								
							</div>								
							<div className="form-group">
								<div className="custom-file" id="customFile">
									<input type="file" className={ (this.state.showFormError)?'custom-file-input is-invalid':'custom-file-input'} onChange={this.onChange} />
									<label className="custom-file-label">{ (this.state.filename)?this.state.filename:'Seleccionar Archivo'}</label>
									{
										this.state.showFormError ? 
										<div className="invalid-feedback">Extension o tamaño maximo no permitido </div> 
										: <small className="form-text text-muted">Permitidos: .jpg, .png, .pdf, .docx, .doc, .xlsx, .xls, tamaño maximo: 5MB</small>										
									}
								</div>
							</div>
							<div className="form-group">
								<label forhtml="recurso">Recursos</label>
								<Select
									name="idcodigoqr"
									value={this.state.value || ''}
									isSearchable={true}
									filterOption={this.customFilter}
									onChange={this.onChangeRecurso}
									options={this.state.optionsRecursos}
								/>
							</div>	
							<button className="btn btn-danger float-right" onClick={this.props.history.goBack}>Cancelar</button>
							{
								(this.state.showFormError)?
								''
								: <button type="submit" className="btn btn-success float-right mr-2">Guardar</button>									
							}
							
						</form>
						
					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(AddForm);