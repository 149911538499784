import React, { Component } from 'react';
import { PostData } from '../../services/PostData';
import './Header.css';
import logo from '../../assets/img/logo.png'
class Header extends Component {
	constructor(...props) {
		super(...props);

		this.state = {
			data: [],
			data2: [],
			tipo:'',
			is_loguin:false
		}
        this.logout = this.logout.bind(this);
        console.log(`${process.env.PUBLIC_URL}/`)
	}
	componentDidMount() {		
		let data_user = sessionStorage.getItem('userData');
		let t = sessionStorage.getItem("userTipo");	
		this.setState({ tipo: t });
		if (data_user) {
			this.setState({
				data: JSON.parse(data_user),
				is_loguin: true
			});
		}
		let data_setting = sessionStorage.getItem('userSetting');		
		if(!data_user){
		 	sessionStorage.setItem("userSetting", '');
			PostData('list_setting', { id: 1 }).then((result) => {
				let responseJson = result;
				if (responseJson.success) {
					sessionStorage.setItem('userSetting', JSON.stringify(responseJson.message));				
					this.setState({ data2: responseJson.message });
				} else {
					this.setState({ data2: [] });
				}
			}); 
		}
		if(data_setting){			
			this.setState({ data2: JSON.parse(data_setting) });
		}	
	}
	logout() {
		sessionStorage.setItem("userData", '');
		sessionStorage.clear();
		this.setState({	is_loguin: true });
	}
  render() {	
    return (					
		<div className="container">
			<nav className="navbar navbar-light navbar-expand-md bg-light mt-2 justify-content-md-center justify-content-start">
				<img src={this.state.data2.logo} className="pull-left mr-2" width="60" height="60" alt="Cargando..."/> 
				<div className="d-md-none d-inline">{this.state.data2.nombre}</div>								
				<div className="navbar-collapse collapse justify-content-between align-items-center w-100">
					<div className="navbar-nav mx-auto text-md-center text-right">
						{this.state.data2.nombre}
					</div>
					<div className="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
						<img src={logo} alt="Logo" width="190" height="70"/>
					</div>
				</div>
			</nav>		
					
			{
				this.state.is_loguin === true ?
				<div className="bg-light" role="alert">
					<div className="row">					
						<div className="col-md-6 text-left text-md-left">
							<h4 className="mt-2 font-weight-light">Hola, <span className=" text-info">{this.state.data.nombre}</span></h4>
							<p className="font-weight-light text-secondary">{this.state.data.email}</p>
						</div>
						<div className="col-md-6 d-flex align-items-center justify-content-end">								
							<form className="form-inline ">
								{
									(this.state.tipo==='1')?
									<a href={`${process.env.PUBLIC_URL}/prehome`} className="btn btn-outline-info mr-sm-2 mr-2">Cambiar Curso</a>
									: (this.state.tipo==='3')?
										''
										: <a href={`${process.env.PUBLIC_URL}/prehome`} className="btn btn-outline-info mr-sm-2">Cambiar Estudiante</a>
								}
								<a href={`${process.env.PUBLIC_URL}/`} onClick={this.logout} className="btn btn-outline-secondary my-2 my-sm-0">Cerrar Sesion</a>
							</form>
						</div>						
					</div>					
				</div>					
				: ''
			}
			<hr/>
		</div>
    );
  }
}

export default Header;