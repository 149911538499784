import React, {Component} from 'react';
import moment from 'moment';
import { FaAddressBook } from 'react-icons/fa';
import { PostData } from '../../services/PostData';
export default class Detalle extends Component {
	constructor(...props) {
		super(...props);
		this.state = {
			fechainicio: moment(),
			fechafinal: moment(),
			titulo: '',			
			curso: '',
			cursos: [],
			idevento:'',
			data: [],			
			descripcion:''	
		}
		this.toggle = this.toggle.bind(this);
	}

	componentDidMount(){
		let postData = { id: this.props.match.params.id };
		PostData('detalle_eventos', postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				let data = responseJson.message;
				this.setState({ titulo: data.titulo,								 
								descripcion: data.descripcion,
								curso: data.curso,
								cursos: data.cursos,
								idevento: data.idevento,
								fechafinal: data.fechafinal,								 
								fechainicio: data.fechainicio });
			} 
		});		
	}

	toggle() {
		this.setState({ modal: !this.state.modal });
	}

	render() {	 
		return (
			<div className="container">
				<div className="row shadow p-3 mb-5 rounded">
					<div className="col-md-12">
						<h3>Detalle Actividad / Evento</h3>
					</div>
					<div className="col-md-12">
						<div className="form-group">
							<label forhtml="titulo" className="text-primary">Titulo</label>
							<span className="form-control-plaintext text-secondary">{`${this.state.titulo}`}</span>
						</div>
						<div className="form-group row">
							<label forhtml="fechainicio" className="col-sm-2 col-form-label text-primary">Fecha Inicio</label>
							<div className="col-sm-10">
								<span className="form-control-plaintext text-secondary">{`${this.state.fechainicio}`}</span>
							</div>
						</div>
						<div className="form-group row">
							<label forhtml="fechafinal" className="col-sm-2 col-form-label text-primary">Fecha Final</label>
							<div className="col-sm-10">
								<span className="form-control-plaintext text-secondary">{`${this.state.fechafinal}`}</span>
							</div>
						</div>						
						<div className="form-group ">
							<label forhtml="descripcion" className="text-primary">Descripcion</label>
							<span className="form-control-plaintext text-secondary text-justify">{`${this.state.descripcion}`}</span>
						</div>
						<div className="form-group ">
							<label forhtml="descripcion" className="text-primary mr-2">Realizado por</label>
							{
								(this.state.cursos.length > 0)?
								this.state.cursos.map((item, index) => (
									<span className="text-danger" key={index}>{item.curso}</span>
								))
								: <span className="text-danger" >General - Colegio</span>
								
							}
						</div>
					</div>			
					<div className="col-md-12 mt-3">
						<button className="btn btn-success float-right" onClick={this.props.history.goBack}><FaAddressBook /> Atras</button>						
					</div>
				</div>
			</div>
		);
	}
}