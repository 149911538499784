import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import { FaCalendarAlt, FaCommentDots } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { Link, withRouter} from 'react-router-dom';

import { PostData } from '../../services/PostData';
import moment from 'moment';
import 'moment/locale/es-do';

class ListaEvento extends Component {
	constructor(...props) {
		super(...props);	
		
		this.state = {			
			startDate: moment(),
			placeholder: '',
			data_momentObj1:[],
			data_momentObj2:[],
			data: []
		}
		this.handleChange = this.handleChange.bind(this);		
	}

	componentDidMount() {
		if (!sessionStorage.getItem("startDate")) {
			let date = moment();
			sessionStorage.setItem('startDate', date);
			this.setState({ startDate: date });
		} else {
			let date = sessionStorage.getItem("startDate");
			var dateObj = new Date(date);
			var momentObj = moment(dateObj);
			var momentString = momentObj.format('DD/MM/YYYY');
			this.setState({ placeholder: momentString });
			this.setState({ startDate: momentObj });
		}
		let t = sessionStorage.getItem("userTipo");
		let postData = { id: 0};
		if(t==='1'){			
			let cursoData = JSON.parse(sessionStorage.getItem("idmateriacursoData"));
			postData = { id: cursoData.idmateriacurso, tipo:t};
		} else{
			let cursoData = JSON.parse(sessionStorage.getItem("userCurso"));
			postData = { id: cursoData.idcurso, tipo: t};
		}
		
		PostData('list_eventos_all', postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				let data_curso = [], dcurso = responseJson.message.data_curso;
				let data_colegio = [], dcolegio = responseJson.message.data_colegio;					
				dcurso.map(item => {
					var dateObj1 = new Date(item);
					var momentObj1 = moment(dateObj1);
					data_curso.push(momentObj1);
					return true;
				});
				dcolegio.map(item => {
					var dateObj2 = new Date(item);
					var momentObj2 = moment(dateObj2);
					data_colegio.push(momentObj2);
					return true;
				});
				this.setState({ data_momentObj1: data_curso, data_momentObj2: data_colegio });
			} else {
				this.setState({ data_momentObj1: [],data_momentObj2: [] });
			}
		});
			
		this.fetchData();
	}

	fetchData() {
		let date = sessionStorage.getItem("startDate");
		if (date) {
			var dateObj = new Date(date);
			var momentObj = moment(dateObj);
			var momentString = momentObj.format('YYYY-MM-DD');								
			let postData = { fecha: momentString};
			PostData('list_eventos', postData).then((result) => {
				let responseJson = result;
				if (responseJson.success) {
					this.setState({ data: responseJson.message });
				} else {
					this.setState({ data: [] });
				}
			});
		}
	}

	handleChange(date) {
		if (date) {
			sessionStorage.setItem('startDate', date);			
			this.setState({ startDate: date });
			this.fetchData();			
		}
	}	
	render() {
		const highlightWithRanges = [
			{ 
				"react-datepicker__day--highlighted-custom-1": this.state.data_momentObj1
			},
			{ 
				"react-datepicker__day--highlighted-custom-2": this.state.data_momentObj2
			}
		  ];
		return (
			<div className="shadow p-3 mb-5 rounded">
				<div className="row">
					<div className="col-md-12">
						<div className="input-group mb-3">
							<DatePicker																					
								dateFormat="DD/MM/YYYY"
								selected={this.state.startDate}
								onChange={this.handleChange}								
								placeholderText={this.state.placeholder}								
								highlightDates={highlightWithRanges}
								disabledKeyboardNavigation	
								locale={moment.locale('es-do')}							
								className="form-control"
							/>
							<div className="input-group-append">
								<span className="input-group-text" id="basic-addon2">
									<FaCalendarAlt />
								</span>
							</div>
						</div>	
					</div>					
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="table-responsive">
							<table className="table table-sm table-hover tbody2">
								<thead className="thead-dark">
									<tr>
										<th scope="col">Hora - Inicial <span className="text-muted small ml-2">Final</span></th>
										<th scope="col">Titulo</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.data.map((item, index) => (
											<tr key={index}>
												<th scope="row">
													<span>{item.horainicial}</span>
													<span className="text-muted small ml-2">{item.horafinal}</span>
												</th>
												<td>
													<div>
														<p className="text-left m-0 p-0 text-primary">{item.titulo}</p>
														<p className="text-justify small m-0 p-0 text-danger">
															{
																(item.tipo==='0')?
																item.curso
																:'General - Colegio'
															}
														</p>
													</div>
												</td>
												<td>
													<Link to={`${process.env.PUBLIC_URL}/home/evento/detalle/${item.idevento}`} className="font-weight-primary mx-2 float-left"><Badge color="info" ><FaCommentDots /></Badge></Link>														
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>				
			</div>
		);
	}

}

export default withRouter(ListaEvento);