import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { FaAddressBook, FaUserEdit } from 'react-icons/fa';
import { PostData } from '../../services/PostData';

export default class ListaRevision extends Component {
	constructor(...props) {
		super(...props);

		this.state = {
			user: [],			
            data: [],
            titulo: '',
            redirectToReferrer: false
		}
    }
    
    componentWillMount(){
        if (sessionStorage.getItem("userData")) {
            let data_user = JSON.parse(sessionStorage.getItem("userData"));
			this.setState({ user: data_user });
		}	
    }

	componentDidMount() {		
        let postData = { id: this.props.match.params.id };
		PostData('detalle_agenda', postData).then((result) => {
            let responseJson = result;
			if (responseJson.success) {
				let data = responseJson.message;
				this.setState({ titulo: data.titulo });
			} 
        });
		let postData2 = { id: this.props.match.params.id };
        PostData('list_revision', postData2).then((result) => {
            let responseJson = result;
            if (responseJson.success) {						
                this.setState({ data: responseJson.message });
            } else {
                this.setState({ data: [] });          
            }
        });        
	}	

	
	render() {        
		return (
			<div className="shadow p-3 mb-5 rounded">
                <div className="row">
                    <div className="col-md-6 offset-md-3 col-sm-6 offset-sm-4 text-center ">
						<h4 className="mt-2">LISTA DE ESTUDIANTES - TAREAS</h4>
					</div>
                    <div className="col-md-12 text-left ">
						<h6 className="mt-2">{this.state.titulo}</h6>
					</div>						
                </div>				
				<div className="row">
					<div className="col-md-12">
						<div className="table-responsive">
							<table className="table table-sm table-hover tbody2">
								<thead className="thead-dark">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Nombre del Estudiante</th>
										<th scope="col" style={{textAlign:'right'}}>Nota</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.data && this.state.data.map((item, index) => (											
											<tr key={index} >
												<th scope="row">{index + 1}</th>
												<td>
                                                    <p className="text-left m-0 p-0">{item.nombre}</p>
												</td>
                                                <td>
                                                    <div className="text-right">
                                                        <Link to={`${process.env.PUBLIC_URL}/home/tarea/revision/${item.idtarea}/${this.props.match.params.id}`} className="font-weight-primary mx-2"><Badge color="primary" ><FaUserEdit /> {item.nota?item.nota:' - '} </Badge></Link>
                                                    </div>
                                                </td>												
											</tr>
										))
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12"><br />
                    <button className="btn btn-success float-right" onClick={this.props.history.goBack}><FaAddressBook /> Atras</button>
					</div>
				</div>
			</div>
		);
	}

}
