import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {PostData} from '../../services/PostData';
import './Login.css';

class Login extends Component {
  constructor(...props) {
    super(...props);
	
    this.state = {
		username:'',
		password:'',
		messages:'',
		data_hijos:[],
		tipo:'',	 
		// redirectToReferrer: false,
		showFormSuccess: false
    };

    this.submitHandle = this.submitHandle.bind(this);
    this.onChange = this.onChange.bind(this);
	}  
	
  submitHandle(e) {
	  e.preventDefault();	  
    if(this.state.username && this.state.tipo){
      PostData('login',this.state).then((result) => {
		let responseJson = result;
		if(responseJson.success){         
			sessionStorage.setItem('userData', JSON.stringify(responseJson.message));
			sessionStorage.setItem('cursosData', JSON.stringify(responseJson.message.cursos));
			//  this.setState({ redirectToReferrer: true});
			if(this.state.tipo==='3'){
				let data_user = JSON.parse(sessionStorage.getItem("userData"));								
				let postData = {
					idcurso: (data_user.idcurso) ? data_user.idcurso : '',
					grado: (data_user.nombre) ? data_user.nombre : '',
					hijos: (responseJson.message.cursos) ? responseJson.message.cursos : ''
				};

				sessionStorage.setItem('userCurso', JSON.stringify(postData));
				window.location.href = `${process.env.PUBLIC_URL}/home`;
			}
			else {
				  window.location.href = `${process.env.PUBLIC_URL}/prehome`;
			}
		}else{
			this.setState({ messages: responseJson.error, showFormSuccess: true});
		}       
	  });
	} else { this.setState({ messages: "Debe ingresar el Tipo de usuario", showFormSuccess: true }); }
    
   }

  	onChange(e){	  
    	this.setState({[e.target.name]:e.target.value});
	}
	
	renderSuccessMessage() {
		return (
			<div className="alert alert-danger" role="alert">
				{this.state.messages}
			</div>
		);
	}
	renderWelcomeMessage() {
		let array_tipo = ['Profesor','Padre','Estudiante'];
		return (
			<div>				
				<h3 className="text-primary">Bienvenido, {array_tipo[this.state.tipo*1-1]} </h3><p>Ingrese sus datos:</p>
			</div>
		);
	}

	componentDidMount(){
		let t = sessionStorage.getItem("userTipo");
		this.setState({tipo: t});
	}

 	render() {
	
/* 
     if (this.state.redirectToReferrer) {
      window.location.href = `${process.env.PUBLIC_URL}/prehome`;
    } */
   
    if(sessionStorage.getItem('userData')){
      return (<Redirect to={`${process.env.PUBLIC_URL}/prehome`}/>)
    }
     
     return (
		
		<div className="container" id="wrapper">			
			<div className="row">
				<div className="col-md-8 offset-md-2">
					{this.state.tipo!=='' ? this.renderWelcomeMessage() : null}
				</div>
				<div className="col-md-12">
					{this.state.showFormSuccess ? this.renderSuccessMessage() : null}				
				</div>
				<div className="col-md-8 offset-md-2">
					<form onSubmit={this.submitHandle} method="post" >						
						<input type="hidden" name="tipo" value={this.state.tipo}/>
						<div className="form-group">
							<label htmlFor="username">Usuario</label>
							<input type="numeric" className="form-control" id="username" name="username" onChange={this.onChange} required={true} aria-describedby="usernameHelp" placeholder="Ingrese sus Datos" />
							<small id="emailHelp" className="form-text text-muted font-weight-light">El campo usuario esta segun su tipo.</small>
						</div>
						{ 
							(this.state.tipo!=='3') ? 
							<div className="form-group">
								<label htmlFor="password">Contraseña</label>
								<input type="password" className="form-control" name="password" id="password" placeholder="Contraseña" onChange={this.onChange} required={true} />
							</div>
							: null
						}
						
						 <button type="submit" className="btn btn-outline-primary float-left">Iniciar Sesion</button>
						 <button className="btn btn-outline-danger float-right" onClick={this.props.history.goBack}>Cancelar</button>
					</form>
				</div>
			</div>
			
		</div>
    );
  }
}

export default Login;