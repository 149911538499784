import React, {Component} from 'react';
import './Tarea.css';
import { FaComments, FaAddressBook } from 'react-icons/fa';
import { Badge, } from 'reactstrap';
import { PostData } from '../../services/PostData';
import FormRespuestas from "./FormRespuestas";
export default class Consulta extends Component {
	constructor(...props) {
		super(...props);
		this.state = {
			titulo:'',
			data: []
		};		
		this.handleClick = this.handleClick.bind(this);		
		this.submitHandleRespuesta = this.submitHandleRespuesta.bind(this);	
	}

	componentDidMount() {
		let id = this.props.match.params.id
		this.fetchData(id)
	}
	
	submitHandleRespuesta(e) {
		e.preventDefault();
		let form = e.target,
			postData = {
				idrespuesta: (form.idrespuesta.value) ? form.idrespuesta.value : '',
				idconsulta: (form.idconsulta.value) ? form.idconsulta.value : '',
				descripcion: (form.descripcion.value) ? form.descripcion.value : ''
			};
		PostData('save_respuesta_consulta_agenda', postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {				
				this.fetchData(this.props.match.params.id)
			}
		});
	}

	handleClick(item) {
		let updatedList = this.state.data.map(obj => {
			if (obj.idconsulta === item.idconsulta) {
				return Object.assign({}, obj, {
					hidden: !item.hidden
				});
			}
			return obj;
		});
		this.setState({
			data: updatedList
		});
	}

	fetchData(idagenda){
		let postData = { id: idagenda };
		PostData('consulta_agenda', postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				this.setState({ data: responseJson.message.data });
				this.setState({ titulo: responseJson.message.titulo });
			} else {
				this.setState({ data: [] });
			}
		});
	}

  	render() {	  
		return (
			<div className="shadow p-3 mb-5 rounded">			
				<div className="row">
					<div className="col-md-12">
						<h3>Consulta Agenda</h3>
						<p className="text-dark font-weight-bold">{this.state.titulo}</p>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="table-responsive">
							<table className="table table-sm table-hover tbody">
								<thead className="thead-dark">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Consulta</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.data.map((item, index) => (
											<tr key={index}>
												<th scope="row">{index + 1}</th>
												<td className="small">												
													{	(item.hidden)?
														<div>
															<p className="text-justify mb-0 font-weight-normal text-body">
																{item.descripcion}
																<span className="ml-2 text-primary font-italic">{`(${item.estudiante})`}</span>
																<span className="font-weight-normal text-body font-italic small"> - responsable:</span>
																<span className="ml-2 text-success font-italic">{`${item.padre}`}</span> <Badge onClick={() => this.handleClick(item)} color="primary" className="float-right "><FaComments /></Badge>
															</p>
															<p className="text-left text-muted mb-0"><span className="text-danger">R. </span>{item.respuesta}</p>
														</div>
														: <FormRespuestas onRespuesta={this.submitHandleRespuesta} idconsulta={item.idconsulta} idrespuesta={item.idrespuesta} idpadre={item.idconsulta} onCancel={() => this.handleClick(item)} />
													}
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<button className="btn btn-success float-right" onClick={this.props.history.goBack}><FaAddressBook /> Atras</button>
					</div>
				</div>
		</div>
		);
  	}
}