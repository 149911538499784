import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import { FaCalendarAlt, FaPlus, FaTrashAlt, FaCommentDots, FaEdit, FaPaperclip, FaTasks } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { Link, withRouter} from 'react-router-dom';

import { PostData } from '../../services/PostData';
import moment from 'moment';
import 'moment/locale/es-do';

class ListaTarea extends Component {
	constructor(...props) {
		super(...props);

		this.state = {			
			startDate: null,
			placeholder:'',
			user: [],			
			data: []
		}
		this.handleChange = this.handleChange.bind(this);
		this.onDeleteAgenda = this.onDeleteAgenda.bind(this);
	}

	componentDidMount() {
		if (sessionStorage.getItem("userData")) {
			let data_user = JSON.parse(sessionStorage.getItem("userData"));
			this.setState({ user: data_user });
		}
		if (!sessionStorage.getItem("startDate")) {			
			let date = moment();
			sessionStorage.setItem('startDate', date);
			this.setState({ startDate: date });
			
		} else{
			let date = sessionStorage.getItem("startDate");
			var dateObj = new Date(date);
			var momentObj = moment(dateObj);
			var momentString = momentObj.format('DD/MM/YYYY');
			this.setState({ placeholder: momentString });
			this.setState({ startDate: momentObj });			
		}
		this.fetchData();
	}

	fetchData() {
		let date = sessionStorage.getItem("startDate");
		if (date) {			
			var dateObj = new Date(date);
			var momentObj = moment(dateObj);
			var momentString = momentObj.format('YYYY-MM-DD'); 
			let idmateria = 0,idcurso=0;
			let t = sessionStorage.getItem("userTipo");			
			if (t === '1'){
				let cursoData = JSON.parse(sessionStorage.getItem("idmateriacursoData"));
				let data_cursos = JSON.parse(sessionStorage.getItem("cursosData"));
				let array_search_curso = data_cursos.find((e) => (e.idmateriacurso === cursoData.idmateriacurso));
				idmateria = array_search_curso.idmateria;
				idcurso = array_search_curso.idcurso;
				let postData = { fecha: momentString, id: idmateria, idc: idcurso };
				PostData('list_agenda', postData).then((result) => {
					let responseJson = result;
					if (responseJson.success) {						
						this.setState({ data: responseJson.message });
					} else {
						this.setState({ data: [] });
					}
				});
			} else {			
				let array_search_curso = JSON.parse(sessionStorage.getItem("userCurso"));
				idcurso = array_search_curso.idcurso;
				let postData = { fecha: momentString, id: idmateria, idc: idcurso };
				PostData('list_agenda', postData).then((result) => {
					let responseJson = result;
					if (responseJson.success) {
						let list_all = responseJson.message.filter((agenda) => (agenda.idpadre===this.state.user.id) || (agenda.idpadre==='0') );						
						this.setState({ data: list_all });
					} else {
						this.setState({ data: [] });
					}
				});
			}

			
		}
	}

	handleChange(date) {
		if (date) {
			sessionStorage.setItem('startDate', date);
			this.setState({ startDate: date });
			this.fetchData();			
		}
	}
	onDeleteAgenda = param => e => {
		e.preventDefault();
		if (window.confirm(`Estas seguro que quieres borrarlo?`)) {
			let postData = { id: param };
			PostData('delete_agenda', postData).then((result) => {
				let responseJson = result;
				if (responseJson.success) {
					this.fetchData();
				}
			});
		}
	}
	submitHandleConsulta = (e) => {
		e.preventDefault();
		let form = e.target,
			postData = {
				idagenda: (form.idagenda.value) ? form.idagenda.value : '',
				descripcion: (form.descripcion.value) ? form.descripcion.value : ''
			};
		PostData('save_consulta_agenda', postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				this.fetchData();
			}
		});
	}
	render() {		
		const cat = {'1': '', '2':'Comunicado','3':'Llamada de Atención', '4':'Actividad en Clase', '5': 'Horario de Clases Virtuales' }
		return (
			<div className="shadow p-3 mb-5 rounded">
				<div className="row">
					<div className="col-md-12">
						<div className="input-group mb-3">
							<DatePicker																					
								dateFormat="DD/MM/YYYY"
								selected={this.state.startDate}
								onChange={this.handleChange}
								placeholderText={this.state.placeholder}
								locale={moment.locale('es-do')}	
								className="form-control"
							/>
							<div className="input-group-append">
								<span className="input-group-text" id="basic-addon2">
									<FaCalendarAlt />
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="table-responsive">
							<table className="table table-sm table-hover tbody2">
								<thead className="thead-dark">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Titulo</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.data.map((item, index) => (											
											<tr key={index} className={
                                                                        (item.categoria==='3')?
                                                                                                'table-danger'
                                                                                              : (item.categoria==='2')?
                                                                                                'table-success'
                                                                                              : (item.categoria==='5')?
                                                                                                'table-info'
                                                                                              : ''
                                                                        }
                                            >
												<th scope="row">{index + 1}</th>
												<td>
													{
														(this.state.user.role === '3') ?														
															<p className="text-left m-0 p-0">	{item.titulo}</p>
														:
														<div>
															<p className="text-left m-0 p-0 text-primary">{item.titulo}</p>
															<p className="text-justify small m-0 p-0">
																<span className="text-info">{item.materia}</span>
																{
																	(item.categoria!=='1')?
																	<span className="text-dark"> - {cat[item.categoria]}</span>	
																	:''
																}
															</p>
														</div>
													}
												</td>
												<td>
                                                    {
                                                        (this.state.user.role === '3') ?
                                                                <div className="text-right">
                                                                    <Link to={`${process.env.PUBLIC_URL}/home/tarea/detalle/${item.idagenda}`} className="font-weight-primary mx-2 float-left"><Badge color="info" ><FaCommentDots /></Badge></Link>
                                                                    <Link to={`${process.env.PUBLIC_URL}/home/tarea/consulta/${item.idagenda}`} className="font-weight-primary mx-2 float-left"><Badge color="warning" >{item.total} <FaCommentDots /></Badge></Link>
                                                                    {
                                                                        (item.categoria==='1')?
                                                                            <Link to={`${process.env.PUBLIC_URL}/home/tarea/listRevision/${item.idagenda}`} className="font-weight-primary mx-2 float-left"><Badge color="secondary" ><FaTasks /></Badge></Link>
                                                                        :''
                                                                    }                                                                    
                                                                    <Link to={`${process.env.PUBLIC_URL}/home/tarea/add/${item.idagenda}`} className="font-weight-primary mx-2"><Badge color="primary" ><FaEdit /></Badge></Link>
                                                                    <Badge onClick={this.onDeleteAgenda(item.idagenda)} href="#" color="danger" ><FaTrashAlt /></Badge>
                                                                </div>
                                                            : 
                                                                <div>
                                                                    <Link to={`${process.env.PUBLIC_URL}/home/tarea/detalle/${item.idagenda}`} className="font-weight-primary mx-2 float-left"><Badge color="info" ><FaCommentDots /></Badge></Link>                                                                    
                                                                    {
                                                                        (item.categoria==='1')?
                                                                            <Link to={`${process.env.PUBLIC_URL}/home/tarea/addTarea/${item.idagenda}`} className="font-weight-primary mx-2 float-left"><Badge color="secondary" ><FaPaperclip /></Badge></Link>	
                                                                        :''
                                                                    }
                                                                </div>
													}
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12"><br />
						{
							(this.state.user.role === '3') ?
								<Link to={`${process.env.PUBLIC_URL}/home/tarea/add/new`}>
									<button type="button" className="btn btn-success btn-circle btn-xl float-right"> <FaPlus /> </button>
								</Link>
								: <br />
						}
					</div>
				</div>
			</div>
		);
	}

}

export default withRouter(ListaTarea);