export function PostData(type, userData) {
    let path_url  = sessionStorage.getItem('path_url')?sessionStorage.getItem('path_url'):window.location.href;
    console.log(path_url+'admin/api/');  
	let BaseURL = path_url+'admin/api/';
	//let BaseURL = 'http://localhost/admin/api/';
    console.log(BaseURL+type);
    return new Promise((resolve, reject) =>{         
        fetch(BaseURL+type, {
			method: 'POST',		
			credentials: 'include',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
            body: JSON.stringify(userData)
          })
          .then((response) => response.json())
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}