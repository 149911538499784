import React, {Component} from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
export default class FormRespuestas extends Component {
  render() {	
    return (
		<form onSubmit={this.props.onRespuesta} className="form-inline">
			<input type="hidden" name="idconsulta" value={this.props.idconsulta} />					
			<input type="hidden" name="idrespuesta" value={this.props.idrespuesta} />					
			<input type="hidden" name="idpadre" value={this.props.idpadre} />					
			<input type="text" className="form-control form-control-sm mb-2 tam" name="descripcion" placeholder="Ingrese su respuesta" />
			<button onClick={this.props.onCancel} className="btn btn-sm btn-danger float-right mx-2 mr-2"><FaTimes /></button>
			<button type="submit" className="btn btn-sm btn-primary float-right"><FaCheck /></button>					
		</form>					
    );
  }
}