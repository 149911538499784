import React, { Component } from 'react';
import { FaDownload, FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link, withRouter} from 'react-router-dom';
import Pagination from "react-js-pagination";
import { Badge } from 'reactstrap';
import { PostData } from '../../services/PostData';
class ListaRecurso extends Component {
	constructor(...props) {
		super(...props);

		this.state = {
			activePage: 1,
      		itemPerPage: 10,
			title:'',
			user:[],
			data2: [],
			data: []
		}
		this.onDeleteAgenda = this.onDeleteAgenda.bind(this);
		this.handleSearchUpdated = this.handleSearchUpdated.bind(this);		
	}

	componentDidMount(){
		if (sessionStorage.getItem("userData")) {
			let data_user = JSON.parse(sessionStorage.getItem("userData"));
			this.setState({ user: data_user });
		}
		this.fetchData(this.state.title, 1);			
	}

	fetchData(title, page) {
		let t = sessionStorage.getItem("userTipo");	
		let postData = { titulo: title, id: 0, idm:0};	
		if(t==='1'){
			let cursoData = JSON.parse(sessionStorage.getItem("idmateriacursoData"));
			let data_cursos = JSON.parse(sessionStorage.getItem("cursosData"));
			let array_search_curso = data_cursos.find((e) => (e.idmateriacurso === cursoData.idmateriacurso));		
			postData = { titulo: title, id: 0, idm: array_search_curso.idmateria};
		} else{
			let cursoData = JSON.parse(sessionStorage.getItem("userCurso"));
			postData = { titulo: title, id: cursoData.idcurso, idm: 0};
		}

		PostData('list_recursosa', postData).then((result) => {
			let responseJson = result;
			if (responseJson.success) {
				this.setState({ data: responseJson.message, data2: responseJson.message });
			} else {
				this.setState({ data: [],data2: [] });
			}
		});
	}
	onDeleteAgenda = param => e => {
		e.preventDefault();
		if (window.confirm(`Estas seguro que quieres borrarlo?`)) {
			let postData = { id: param };
			PostData('delete_recurso', postData).then((result) => {
				let responseJson = result;
				if (responseJson.success) {
					this.fetchData(this.state.title);
				}
			});
		}
	}
	handlePageChange(pageNumber) {
		this.setState({ activePage: pageNumber });
	  }
	handleSearchUpdated(e) {
		var updatedList = this.state.data;				
		updatedList = updatedList.filter((item) => item.titulo.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
		this.setState({ data2: updatedList });
	}	
	render() {
		const { data2, activePage, itemPerPage } = this.state;

		const indexOfLastTodo = activePage * itemPerPage;
		const indexOfFirstTodo = indexOfLastTodo - itemPerPage;
		const renderedProjects = data2.slice(indexOfFirstTodo, indexOfLastTodo);		
		return (
			<div className="shadow p-3 mb-5 rounded">
				<div className="row">
					<div className="col-md-12">						
						<input type="text" className="form-control form-control-sm" placeholder="Ingrese el Titulo del Recurso para buscar" onChange={this.handleSearchUpdated} />
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-12">
						<div className="table-responsive">
							<table className="table table-sm table-hover tbody2">
								<thead className="thead-dark">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Titulo</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{
										renderedProjects.map((item, index) => (
											<tr key={index}>
												<th scope="row">
													{index+1}
												</th>
												<td>
													<p className="text-left m-0 p-0 text-primary small">{item.titulo}</p>													
												</td>
												<td>
													<a href={item.url} download target='_blank' rel="noopener noreferrer" className="small alert-info text-info float-left">Click para Descargar   <FaDownload /></a>
													{(this.state.user.role === '3') ?
														<div className="text-right">
															<Link to={`${process.env.PUBLIC_URL}/home/recurso/add/${item.idrecurso}`} className="font-weight-primary mx-2"><Badge color="primary" ><FaEdit /></Badge></Link>
															<Badge onClick={this.onDeleteAgenda(item.idrecurso)} href="#" color="danger" ><FaTrashAlt /></Badge>
														</div>
														: ''
													}
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-8"><br />
					<Pagination
						activePage={this.state.activePage}
						itemsCountPerPage={this.state.itemPerPage}
						totalItemsCount={this.state.data.length}
						pageRangeDisplayed={5}
						firstPageText="Primero"
						lastPageText="Ultimo"
						prevPageText="Atras"
						nextPageText="Adelante"
						linkClass="page-link"
						itemClass="page-item"
						onChange={this.handlePageChange.bind(this)}
					/>
					</div>
					<div className="col-md-4"><br />
						{
							(this.state.user.role === '3') ?
								<Link to={`${process.env.PUBLIC_URL}/home/recurso/add/new`}>
									<button type="button" className="btn btn-success btn-circle btn-xl float-right"> <FaPlus /> </button>
								</Link>
								: <br />
						}
					</div>
				</div>				
			</div>
		);
	}

}

export default withRouter(ListaRecurso);