import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import ListaRecurso from "./ListaRecurso";
import AddForm from "./AddForm";
class Recurso extends Component {		
	render() {				
		return (
			<div className="container " id="wrapper">
				<div className="row">					
					<div className="col-md-6 offset-md-3 col-sm-6 offset-sm-4 text-center ">
						<h3 className="mt-2">RECURSOS</h3>
					</div>
				</div>

				<Route exact path={`${process.env.PUBLIC_URL}/home/recurso`} component={ListaRecurso} />
				<Route path={`${process.env.PUBLIC_URL}/home/recurso/add/:id`} component={AddForm} />
								
			</div>
		);
    }
}

export default withRouter(Recurso);